var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contactForm" },
    [
      _c("contact", {
        attrs: {
          context: _vm.context.pageContext,
          "mail-type": _vm.mailType,
          email: _vm.context.data.get("email", null),
          token: _vm.context.data.get("token", null),
          "success-type": this.context.data.get("successMessage", "default"),
          customer: _vm.customer,
          "additional-payload": { branchCode: _vm.currentBranchCode },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }