
























import {inflectToSlug} from '@labor-digital/helferlein/lib/Strings/Inflector/inflectToSlug';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';

export default {
    name: 'Anchor',
    props: {
        context: null as ContentElementContext
    },
    computed: {
        id()
        {
            return encodeURIComponent(inflectToSlug(this.context.data.get('label')));
        }
    }
};
