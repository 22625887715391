var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "mobileSubMenuModule" },
    _vm._l(_vm.mainMenuComponent.itemsSubPages, function (item) {
      return _c(
        "li",
        {
          staticClass: "mainMenuMobile__listItem",
          class: _vm.mainMenuComponent.activeItemClass(item),
        },
        [
          _c(
            "link-wrap",
            {
              attrs: {
                link: item.href,
                "emit-on-click": _vm.mainMenuComponent.hasChildren(item),
              },
              on: {
                click: function ($event) {
                  return _vm.mainMenuComponent.pageNext(item)
                },
              },
            },
            [
              _vm._v("\n            " + _vm._s(item.title) + "\n            "),
              _vm.mainMenuComponent.hasChildren(item)
                ? _c("icon", {
                    staticClass: "children",
                    attrs: { icon: "arrowLeft" },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }