































import {forEach, isEmpty} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import Contact from '../../../Component/Forms/Contact/Contact.vue';
import {Branch} from '../../../Interface/Branch';
import {BranchAndGeoLocationService} from '../../../Service/BranchAndGeoLocationService';

export default {
    name: 'ContactForm',
    components: {Contact},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            customer: this.context.data.get('customer', null)
        };
    },
    computed: {
        mailType()
        {
            return isEmpty(this.customer) ? 'contact' : 'landingPage';
        },
        currentBranchCode(): number | null
        {
            let code = null;
            forEach([
                this.context.initialState.branch,
                BranchAndGeoLocationService.getCurrentBranch()
            ], (branch: Branch | null) => {
                if (branch) {
                    code = branch.code;
                    return false;
                }
            });

            return code;
        }
    }
};
