
































import Icon from '../../../../Misc/Icon/Icon.vue';
import HomeLinkWrap from '../../../../Misc/Link/HomeLinkWrap/HomeLinkWrap.vue';
import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap.vue';

export default {
    name: 'MobileSubMenuModule',
    components: {HomeLinkWrap, LinkWrap, Icon},
    props: {
        mainMenuComponent: {
            type: Object,
            required: true
        }
    }
};
