
























export default {
    name: 'AdventCalendar',
    mounted()
    {
        let recaptchaScript = document.createElement('script');
        recaptchaScript.setAttribute('src', 'https://tuerchen.app/embed.js?14');
        document.head.appendChild(recaptchaScript);
    }
};
