var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "AdventCalendar" }, [
      _c("div", {
        attrs: {
          "data-tuerchen_embed": "",
          "data-public_key": "Adventskalender-persona-service",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }